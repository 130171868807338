<template>
  <div id="app">
    <router-view v-if="router_if"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  // watch:{
  //   $route(to,from){
  //     this.router_if=false;
  //     console.log(to.path);
  //     this.router_if=true;
  //   }
  // },
  created(){
      window.thisWindow=this;
  },
  data() {
      return {
         router_if:true,
      }
    },
  components: {
    
  }
}
</script>

<style>
* {padding: 0;margin: 0;box-sizing: border-box;}
	html { height: 100%;width: 100%;}
	body {height: 100%;width: 100%;}
	#app {height: 100%;width: 100%; font-size: 14px;}
  .color_logo {color:#37abbb}
  .display_align_center {display: flex; align-items: center;}
  .display_justify_center {display: flex; justify-content: center;}
  .display_justify_start {display: flex; justify-content:flex-start;}
  .display_justify_end {display: flex; justify-content:flex-end;}
  .display_center {display: flex; align-items: center; justify-content: center;}
  .wh {width:100%;height: 100%;}
  .wh_is {width:100%;height: 100%;background-color:#ecf5ff;padding: 10px;}
  .wh_is_phone {width:100%;height: 100%;background-color:#ecf5ff;padding: 10px;}
  .c_input {border: 1px solid #b4bccc; border-radius: 4px;}
  .van-uploader__upload {border: 1px dotted #b4bccc;}



</style>
