<template>
   <div class="heard">
      <img class="logo" src="../..//../assets/icons/logo_t.png"/>
      <img class="showlist" src="../../../assets/icons/showList.png" @click="show=0" width="40" style="float: right; margin-right: 20px;"  :class="show===0? 'top_show':'top_hiden' "/>
      <div style="width:510px; height: 30px; float: right;   transition: transform 0.3s linear; " :class="show===0? 'top_hiden':'top_show' ">
        <ul class="heard_ul">
          <li  @click="click_e('index','0')" :class="li_num===0? 'checked_li':'No_checked_li' "><span class="en">首页</span><span class="ch">WORK</span></li>
          <li  @click="click_e('works','1')" :class="li_num===1? 'checked_li':'No_checked_li' "><span class="en">作品</span><span class="ch">WORK</span></li>
          <li  @click="click_e('about','2')" :class="li_num===2? 'checked_li':'No_checked_li' "><span class="en">关于</span><span class="ch">ABOUT</span></li>
          <li  @click="click_e('news','3')" :class="li_num===3? 'checked_li':'No_checked_li' "><span class="en">新闻</span><span class="ch">NEWS</span></li>
          <li  @click="click_e('contact','4')" :class="li_num===4? 'checked_li':'No_checked_li' "><span class="en">联系</span><span class="ch">CONTACT</span></li>
          <li  ><span class="en">工作</span><span class="ch">JOBS</span></li>       
        </ul>
      </div>  
    </div> 
</template>

<script>

  export default {
    props: {
      
    },
    
    mounted() {
      window.addEventListener("scroll", this.btn_pos);
    
    },
    data() {
      return {   
        show:0,
       
      }
    },
    created(){
      this.li_num =sessionStorage.li_num*1;
      console.log( this.li_num);
    },
    components: {
     
    },
    methods: {
      btn_pos() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 400) {
          this.show=1;        
        } else {
          this.show=0;         
        }      
      },
      click_e(url,num) {
         
          if(num != this.li_num){
            // 编程式导航到 / 路径   
            this.$router.push({name:url});
          }
        }
     
    }
  }
</script>
<style> 


</style>
