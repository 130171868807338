<template>
  <div style="width:100%; height: 100%;">
    <div class="container">
      <!-- <div class="heard_2">
        <a  href="../index"><img class="logo" src="../../../assets/icons/logo_t.png" /></a>
        <div style="width:510px; height: 30px; float: right;   transition: transform 0.3s linear; " :class="show===0? 'top_hiden':'top_show' ">
          <ul class="heard_ul">
            <li @click="click_e('index')"><span class="en">首页</span><span class="ch">WORK</span></li>
            <li  @click="click_e('works')"><span class="en">作品</span><span class="ch">WORK</span></li>
            <li  @click="click_e('about')"><span class="en">关于</span><span class="ch">ABOUT</span></li>
            <li  @click="click_e('news')" ><span class="en">新闻</span><span class="ch">NEWS</span></li>
            <li  @click="click_e('contact')"><span class="en">联系</span><span class="ch">CONTACT</span></li>
            <li ><span class="en">工作</span><span class="ch">JOBS</span></li>      
          </ul>
        </div>
      </div> -->

      <div style=" width: 100%;  height: 90px; position: fixed; top: 0; z-index: 1000;   background-color: #fff; box-shadow:rgba(221, 221, 221,0.5) 0px 0px 20px;">
         <o_head></o_head>
      </div>

      <div class="page">       
       <div>
           <img  v-lazy="images.infoImgs[0]" width="100%"/>
       </div>
       <div>
          <p>&nbsp;</p> 
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p style="margin-left: 40px;"><span style="font-size:36px; color: #595959;"><b>{{images.name[0]}}</b></span></p> 
          <p>&nbsp;</p>
          <span v-for="(name_p, index) in images.name[1]" :key="index">
            <p style="margin-top: 0em; margin-bottom: 0em; text-indent: 0em; letter-spacing: 0em; line-height: 3em; margin-left: 40px;">{{name_p}}</p>
          </span>
          <p>&nbsp;</p> 
          <p style="margin-left: 40px;"><span style="color:#999999;">{{images.name[2]}}</span></p>
          <p>&nbsp;</p> 
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
        <div v-for="(image, index) in images.infoImgs" :key="index">
          <img v-if="index>0"  v-lazy="image"  width="100%"/>
          <p>&nbsp;</p> 
        </div>

        <div class="latest_case" >
          <div class="latest_case_more">MORE</div>          
          <!-- 轮播 -->
          <div class="owl-drag">
            <div style="width:100%; display: flex;  justify-content: space-between;">
              <!--more_images.length==0-->
              <div v-if="images.moreImgs.length==0" class="seemore_nodata">
                <div class="prompt_title"> 
                  <img class="" src="../../../assets/icons/nodata.png"  width="35px" />                 
                  <label class="font_tip">暂时没有内容信息显示</label>
                </div>
              </div>

              <div v-if="more_images.length>0" class="latest_case_Big"   v-for="(image, index) in more_images" :key="index">
               <div class="latest_case_list" >
                  <div  class="latest_case_pic">
                    <img width="100%" style="float: left;" :src="image.url">
                  </div>                
                    <div class="latest_case_title">
                      <h2 class="h5_title">{{image.name}}</h2>
                    </div>                
                </div>
              </div>            
            </div>


            </div>
        </div>

      </div>

      <div>
        <o_footer></o_footer>
      </div>

    </div>   
  </div>
</template>

<script>
  import o_footer  from '../../../components/pages/component/footer.vue'
  import o_head  from '../../../components/pages/component/onexz_head.vue'
  export default {
    props: {
      
    },
    
    mounted() {
      window.addEventListener("scroll", this.btn_pos);      
      this.images = JSON.parse(localStorage.getItem('onework_info'));
      window.scrollTo(0, 0);
    },
    data() {
      return {       
        scrollTop: 0,
        show:0,
        images:[],
        l_img:'http://www.zq-fa.com/oneXZ_official_website/loading.png',
      //  images:["http://www.zq-fa.com/oneXZ_official_website/one_work/info0.jpg","http://www.zq-fa.com/oneXZ_official_website/one_work/info1.jpeg","http://www.zq-fa.com/oneXZ_official_website/one_work/info2.jpeg","http://www.zq-fa.com/oneXZ_official_website/one_work/info3.jpeg"],
        more_images: [{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/1.jpg","name":"yoose有色小冰砖"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/2.jpg","name":"高速加工中心"},{"url":"http://www.zq-fa.com/oneXZ_official_website/banner_imgs/3.jpg","name":"高速加工中心"}],
      
      }
    },
    created(){
      sessionStorage.li_num = 1;
    },
    components: {
      o_footer,o_head
    },
    methods: {
        btn_pos() {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > 400) {
            this.show=1;        
          } else {
            this.show=0;         
          }      
        },
      
    }
  }
</script>
<style>
  .container{
    min-height:100%;
    height: auto !important;
    height: 100%; /*IE6不识别min-height*/
    position: relative;
  }
  
  .page {   
    width:100%;
    margin: 0 auto;  
    margin-top:90px;
    color: #595959;
    font-size: 14px;
  }

  .heard_2 { width: 100%;   padding-top:30px; padding-bottom: 30px; position: fixed; top: 0; z-index: 1000;  padding-left: 5%; background-color: #fff;}
  .logo { float: left; height: 30px; }
  .heard_2  ul li{ float: left; padding: 0 20px 0 20px;list-style: none}
  .heard_2  ul li  {color: #000; font-size:17ccpx; text-shadow: 0px 0px 2px #000000; cursor: pointer;}
  .heard_ul li:hover{ color:#DE303A;  text-shadow: 0px 0px 2px #DE303A;}
  .top_show{opacity: 0; top:-500px; display: none;}
  .top_hide{ opacity: 1; top:0px; display: block;}
  .ch{ display: none}


  .latest_case {
    width: 100%;
    padding: 0;
    margin: 30px 0 15px;
}
.latest_case_more{
   color: #333;
    text-align: left;
    padding: 50px;
    font-weight: 600;
    font-size: 24px;
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.owl-drag{ padding-bottom: 40px;}        
.latest_case_Big{width:33%;background-color: red;float:left; height: auto; position: relative; overflow:hidden;}
.latest_case_list{ width:100%; height: auto; float:left;overflow:hidden; }
.latest_case_pic{width: 100%; height: auto; float:left; overflow:hidden; }
.latest_case_Big:hover .latest_case_title{ opacity:1;}
.latest_case_Big:hover .h5_title{ animation: slide-up2 0.5s ease forwards; /* 动画名称，时长，缓动函数，填充模式 */}
.latest_case_Big:hover img{ overflow:hidden; transform: scale(1.1);    transition: all 0.5s;}
   
.latest_case_title{position: absolute; top:0; left:0; width:100%; height: 100%;
        background-color: rgba(0,0,0,0.5);
        color:#fff;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        opacity: 0; /*初始时隐藏文本 */
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.5s linear;
      }
  /* 定义动画 */
  @keyframes slide-up2 {
      from {
        transform: translateY(10%); /* 从下边开始 */
        opacity: 0; /* 不透明度为0 */
      }
      to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 完全不透明 */
      }
    }
    

.latest_case_title .h5_title{
  cursor: pointer;
  padding:0 10%  8% 10%;
  text-align: center;
  height: 100%;;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}


.prompt_title{
  width:230px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.seemore_nodata{
  width:100%;
   display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  font-size:18px;
  font-weight: bold;
}
.font_tip{
  padding-left: 10px;
}
</style>
