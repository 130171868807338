<template>
  <div style="width:100%; height: 100%;">
    <div class="container">
      <!-- <div class="heard_2">
        <a  href="../index"><img class="logo" src="../../../assets/icons/logo_t.png" /></a>
        <div style="width:510px; height: 30px; float: right;   transition: transform 0.3s linear; " :class="show===0? 'top_hiden':'top_show' ">
          <ul class="heard_ul">
            <li @click="click_e('index')"><span class="en">首页</span><span class="ch">WORK</span></li>
            <li @click="click_e('works')"><span class="en">作品</span><span class="ch">WORK</span></li>
            <li><span class="en">关于</span><span class="ch">ABOUT</span></li>
            <li  @click="click_e('news')"><span class="en">新闻</span><span class="ch">NEWS</span></li>
            <li  @click="click_e('contact')"><span class="en">联系</span><span class="ch">CONTACT</span></li>
            <li><span class="en">工作</span><span class="ch">JOBS</span></li>      
          </ul>
        </div>
      </div> -->

      <div style=" width: 100%;  height: 90px; position: fixed; top: 0; z-index: 1000;   background-color: #fff; box-shadow:rgba(221, 221, 221,0.5) 0px 0px 20px;">
         <o_head></o_head>
      </div>


      <div class="about_page">    
        <p style="margin-top: 0px; margin-bottom: 0px; white-space: normal; box-sizing: border-box; animation-fill-mode: both; padding: 0px; list-style-type: none; line-height: 2; font-family: 微软雅黑, Arial; background-color: rgb(255, 255, 255); text-align: center; visibility: visible; animation-name: fadeInUp;" class="wow fadeInUp animated">
          <strong style="font-family: Arial, Verdana, sans-serif; font-size: 18px;">
            <span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">
              <span style="margin: 0px;">∵<span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">∴</span></span>
            </span>
           </strong>
           <span style="font-size: 26px;">
            <strong style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">
              万喜至工业设计
            </strong>
          </span>
          <strong style="font-family: Arial, Verdana, sans-serif;"><span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">∴∵</span></strong>
        </p>      
        <div>
          <div style="text-align: left; margin-top:20px;">
            <span style="font-size:18px; line-height: 30px;">             
              &nbsp; &nbsp; &nbsp; &nbsp;<strong>广东万喜至设计有限公司(ONEXZ DESIGN)</strong>成立于2007年，座落于东莞市松山湖，是广东省级工业设计中心、国家高新技术企业。 万喜至坚持以定制化服务、产品价值转化为核心，着力品牌策划与研究、工业设计、研发与生产供应链管理等专业化服务，为企业提供整体创新解决方案。<br>&nbsp; &nbsp; &nbsp; &nbsp;历经十余年发展，万喜至工业设计已经成功为海内外众多装备制造企业提供过3000多项服务，所涵盖的机械装备种类有3D打印设备、印刷包装设备、机床设备、激光钣金设备、木工机械设备、纺织绗缝设备、电子电力设备、橡塑机械设备、食品医疗设备、玻璃加工设备、陶瓷生产设备等众多行业装备。服务的客户均为中高端以上，包括大族激光、格力自动化、伊之密等上市公司和龙头企业。<br><br>
            </span></div>
        </div>
        <div style="margin-top:10px;border-radius: 5px;">
          <img  src="http://www.zq-fa.com/oneXZ_official_website/about/about_1.gif" width="100%"/>
        </div>

        <!-- <p style="margin-top: 30px; margin-bottom: 0px; white-space: normal; box-sizing: border-box; animation-fill-mode: both; padding: 0px; list-style-type: none; line-height: 2; font-family: 微软雅黑, Arial; background-color: rgb(255, 255, 255); text-align: center; visibility: visible; animation-name: fadeInUp;" class="wow fadeInUp animated">
          <strong style="font-family: Arial, Verdana, sans-serif; font-size: 18px;">
            <span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">
              <span style="margin: 0px;">∵<span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">∴</span></span>
            </span>
           </strong>
           <span style="font-size: 24px;">
            <strong style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">
             服务模块/SERVICE
            </strong>
          </span>
          <strong style="font-family: Arial, Verdana, sans-serif;"><span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">∴∵</span></strong>
        </p> 
        
        <div style="margin-top:30px;border:1px solid #ccc; background-color: #000; color: #fff; height: 200px; font-size: 25px; border-radius: 5px;">
            <div>
                <ul style="  height: 100px; line-height: 100px;">
                    <li>品牌规划</li>
                    <li>外观设计</li>
                    <li>结构设计</li>
                    <li>钣金制造</li>
                 </ul>
                 <ul style="height: 100px; line-height: 100px;">
                    
                    <li>模型制作</li>
                    <li>功能动画</li>
                    <li>设备UI设计</li>
                </ul>
            </div>
        </div> -->
        
        <p style="margin-top: 30px; margin-bottom: 0px; white-space: normal; box-sizing: border-box; animation-fill-mode: both; padding: 0px; list-style-type: none; line-height: 2; font-family: 微软雅黑, Arial; background-color: rgb(255, 255, 255); text-align: center; visibility: visible; animation-name: fadeInUp;" class="wow fadeInUp animated">
          <strong style="font-family: Arial, Verdana, sans-serif; font-size: 18px;">
            <span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">
              <span style="margin: 0px;">∵<span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">∴</span></span>
            </span>
           </strong>
           <span style="font-size: 26px;">
            <strong style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">
              合作伙伴/Clients
            </strong>
          </span>
          <strong style="font-family: Arial, Verdana, sans-serif;"><span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">∴∵</span></strong>
        </p> 
        
        <div style="margin-top:30px;border:1px solid #ccc; border-radius: 5px;">
          <img  src="http://www.zq-fa.com/oneXZ_official_website/about/Clients.jpg" width="100%"/>
        </div>

        <p style="margin-top: 30px; margin-bottom: 0px; white-space: normal; box-sizing: border-box; animation-fill-mode: both; padding: 0px; list-style-type: none; line-height: 2; font-family: 微软雅黑, Arial; background-color: rgb(255, 255, 255); text-align: center; visibility: visible; animation-name: fadeInUp;" class="wow fadeInUp animated">
          <strong style="font-family: Arial, Verdana, sans-serif; font-size: 18px;">
            <span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">
              <span style="margin: 0px;">∵<span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">∴</span></span>
            </span>
           </strong>
           <span style="font-size: 24px;">
            <strong style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">
              荣誉奖项
            </strong>
          </span>
          <strong style="font-family: Arial, Verdana, sans-serif;"><span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">∴∵</span></strong>
        </p> 
        
        <div style="margin-top:30px;border:1px solid #ccc; border-radius: 5px;">
          <img  src="http://www.zq-fa.com/oneXZ_official_website/about/Clients.png" width="100%"/>
        </div>

        <p style="margin-top: 30px; margin-bottom: 0px; white-space: normal; box-sizing: border-box; animation-fill-mode: both; padding: 0px; list-style-type: none; line-height: 2; font-family: 微软雅黑, Arial; background-color: rgb(255, 255, 255); text-align: center; visibility: visible; animation-name: fadeInUp;" class="wow fadeInUp animated">
          <strong style="font-family: Arial, Verdana, sans-serif; font-size: 18px;">
            <span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">
              <span style="margin: 0px;">∵<span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">∴</span></span>
            </span>
           </strong>
           <span style="font-size: 26px;">
            <strong style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px;">
              企业文化
            </strong>
          </span>
          <strong style="font-family: Arial, Verdana, sans-serif;"><span style="box-sizing: border-box; animation-fill-mode: both; padding: 0px; margin: 0px; font-size: 24px;">∴∵</span></strong>
        </p> 
        
        <div style="margin-top:50px;width:100%; display: flex; padding-bottom: 50px;  justify-content: space-between;">
          <div class="div_Big">
            <div class="div_Big_top">
              <img  src="../../../assets/icons/dw.png" width="50px"/>
              <div class="div_Big_name">企业定位</div>
            </div> 
            <div class="div_Big_value">
              装备制造产业全价值链增值服务缔造者
            </div>  
          </div>

          <div class="div_Big">
            <div class="div_Big_top">
              <img  src="../../../assets/icons/sm.png" width="50px"/>
              <div class="div_Big_name">企业使命</div>
            </div> 
            <div class="div_Big_value">
              让中国装备形象与世界需求同步
            </div>  
          </div>

          <div class="div_Big">
            <div class="div_Big_top">
              <img  src="../../../assets/icons/jzg.png" width="50px"/>
              <div class="div_Big_name">企业价值观</div>
            </div> 
            <div class="div_Big_value">
              向上向善、积极乐观、拥抱变化、永不言弃
            </div>  
          </div>

          <div class="div_Big">
            <div class="div_Big_top">
              <img  src="../../../assets/icons/yj.png" width="50px"/>
              <div class="div_Big_name">企业愿景</div>
            </div> 
            <div class="div_Big_value">
              成为伙伴成长的最佳平台；成为设计服务的最佳选择；成为美好生活不可或缺的促进力量；最终成为最有效的设计生态系统
            </div>  
          </div>


        </div>
      </div>      

      <div>
        <o_footer></o_footer>
      </div>

    </div>   
  </div>
</template>

<script>
  import o_footer  from '../../../components/pages/component/footer.vue'
  import o_head  from '../../../components/pages/component/onexz_head.vue'

  export default {
    props: {
      
    },
    
    mounted() {
      document.title = '关于-广东万喜至工业设计有限公司'; // 设置页面标题
      window.addEventListener("scroll", this.btn_pos);      
      window.scrollTo(0, 0);
    },
    data() {
      return {       
        scrollTop: 0,
        show:0,
      
       
      }
    },
    created(){
      sessionStorage.li_num = 2;
    },
    components: {
      o_footer,o_head
    },
    methods: {
        btn_pos() {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > 400) {
            this.show=1;        
          } else {
            this.show=0;         
          }      
        },
      
    }
  }
</script>
<style>

.div_Big{width: calc((100% - 0px) / 4) !important;}
.div_Big_top{margin:0 auto; text-align: center; padding: 10px 0;}
.div_Big_name{font-size: 20px; font-weight: bold}
.div_Big_value{font-size: 16px; color:#757575;line-height: 1.65; text-align: center}


  .container{
    min-height:100%;
    height: auto !important;
    height: 100%; /*IE6不识别min-height*/
    position: relative;
  }
  
  .about_page {   
    width:88%;
    margin: 0 auto;  
    margin-top:160px; 
  
  }

  .heard_2 { width: 100%;   padding-top:30px; padding-bottom: 30px; position: fixed; top: 0; z-index: 1000;  padding-left: 5%; background-color: #fff;}
  .logo { float: left; height: 30px; }
  .heard_2  ul li{ float: left; padding: 0 20px 0 20px;list-style: none}
  .heard_2  ul li  {color: #000; font-size:17px; text-shadow: 0px 0px 2px #000000; cursor: pointer;}
  .heard_ul li:hover{ color:#DE303A;  text-shadow: 0px 0px 2px #DE303A;}
  .top_show{opacity: 0; top:-500px; display: none;}
  .top_hide{ opacity: 1; top:0px; display: block;}
  .ch{ display: none}


  .works_title{
    width: 100%;
    max-width: none;
    min-width: 0px;
    height: auto;
    max-height: none;
    min-height: 86px;
    margin-top: 130px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-left: 5%; 
  }


  .latest_case {
    width: 100%;
    padding: 0;
    margin: 30px 0 15px;
}
.latest_case_more{
   color: #333;
    text-align: left;
    padding: 50px;
    font-weight: 600;
    font-size: 24px;
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.owl-drag{ padding-bottom: 40px;}        
.p_Product{
        background-color: #fff;
        overflow:hidden; 
        position: relative; 
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 0px) / 3) !important;
        border-width: 3px;
        border-style: solid;
        border-color:#fff;
        height: inherit;
        border-radius: 10px;
        transition: all .4s ease-in-out;


}
.latest_case_list{ width:100%; height: auto; float:left;overflow:hidden; }
.latest_case_pic{width: 100%; height: auto; float:left; overflow:hidden; }
.p_Product:hover .latest_case_title{ opacity:1;}
.p_Product:hover .h5_title{ animation: slide-up2 0.5s ease forwards; /* 动画名称，时长，缓动函数，填充模式 */}
.p_Product:hover img{ overflow:hidden; transform: scale(1.1);    transition: all 0.5s;}
   

      
.latest_case_title{position: absolute; top:0; left:0; width:100%; height: 100%;
        background-color: rgba(0,0,0,0.5);
        color:#fff;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        opacity: 0; /*初始时隐藏文本 */
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.5s linear;
      }
  /* 定义动画 */
  @keyframes slide-up2 {
      from {
        transform: translateY(10%); /* 从下边开始 */
        opacity: 0; /* 不透明度为0 */
      }
      to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 完全不透明 */
      }
    }
    

.latest_case_title .h5_title{
  cursor: pointer;
  padding:0 10%  8% 10%;
  text-align: center;
  height: 100%;;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}


.prompt_title{
  width:230px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.seemore_nodata{
  width:100%;
   display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  font-size:18px;
  font-weight: bold;
}
.font_tip{
  padding-left: 10px;
}
</style>
