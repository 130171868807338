<template>
  <div style="width:100%; height: 100%;">
    <div class="container">
      <!-- <div class="heard_2">
        <a  href="../index"><img class="logo" src="../../../assets/icons/logo_t.png" /></a>
        <div style="width:510px; height: 30px; float: right;   transition: transform 0.3s linear; " :class="show===0? 'top_hiden':'top_show' ">
          <ul class="heard_ul">
            <li @click="click_e('index')"><span class="en">首页</span><span class="ch">WORK</span></li>
            <li  @click="click_e('works')"><span class="en">作品</span><span class="ch">WORK</span></li>
            <li  @click="click_e('about')"><span class="en">关于</span><span class="ch">ABOUT</span></li>
            <li><span class="en">新闻</span><span class="ch">NEWS</span></li>
            <li  @click="click_e('contact')"><span class="en">联系</span><span class="ch">CONTACT</span></li>
            <li><span class="en">工作</span><span class="ch">JOBS</span></li>      
          </ul>
        </div>
      </div> -->

      <div style=" width: 100%;  height: 90px; position: fixed; top: 0; z-index: 1000;   background-color: #fff; box-shadow:rgba(221, 221, 221,0.5) 0px 0px 20px;">
         <o_head></o_head>
      </div>

      <div class="page">    
      
        <div class="works_title">
          <h2 style="width:300px; float:left;">
            <span style="color:#333">
              <span style="font-family:arial,helvetica,sans-serif">
                <span style="font-size:60px">
                  <strong>NEWS</strong>
                </span>
              </span>
            </span>
          </h2>

          <!-- <div class="news-nav clearfix" style=" width: calc(100% - 400px); float: right; ">
            <ul class="hc-ul ul-block clearfix" style=" ">
              <li>
                <div @click="activeIndex(0)"  :class="num===0? 'activetext':'' ">行业资讯 </div>
              </li>
            
              <li >
                <div @click="activeIndex(1)"  :class="num===1? 'activetext':'' "> 万喜至资讯 </div>
              </li>                        
            </ul>
          </div> -->
       </div> 

        <div class="latest_case" >                 
          <!-- 轮播 -->
          <div class="owl-drag">
            <div style="width:100%; border-width: 0 3px 0 3px; border-style: solid; border-color: #fff;">
              <!--more_images.length==0-->
              <div style="width: 100%;display: flex;  justify-content: space-between;">
                <div v-if="News_Info.length==0" class="seemore_nodata">
                  <div class="prompt_title"> 
                    <img class="" src="../../../assets/icons/nodata.png"  width="35px" />                 
                    <label class="font_tip">暂时没有内容信息显示</label>
                  </div>
                </div>
              </div>
            
              <div  v-if="News_Info.length>0"  style="margin-top:20px;">
                <div style="width:100%; display: flex;  justify-content: space-between; flex-wrap: wrap; ">
                      <div class="latest_case_news"  @click="open_news(image.open_url)"    v-for="(image, index) in News_Info" :key="index">
                        <div class="latest_case_nlist">
                            <div  class="latest_case_npic">
                              <img width="100%" style="float: left;" :src="image.url">
                            </div>                
                            <div class="latest_case_ntitle">
                                <div style="padding-bottom:10px;white-space: nowrap;   overflow: hidden;    text-overflow: ellipsis;">{{image.name}}</div>
                                <div style="float:left; font-size:14px;  color:#9c9c9c; vertical-align: middle;"><img src="../../../assets/icons/date.png" width="20px" style="vertical-align: middle; margin-right:10px;"/><span>{{image.date}}</span></div>
                                <div style="float:left; font-size:14px; color:#9c9c9c; margin-left: 30px; vertical-align: middle; margin-top: -5px;"><img src="../../../assets/icons/look.png" width="30px"  style="vertical-align: middle"/> {{image.see_num}}</div>
                              </div>                
                          </div>
                        </div>            
                     </div>
                </div>          
            </div>
            </div>
        </div>

      </div>

      <div>
        <o_footer></o_footer>
      </div>

    </div>   
  </div>
</template>

<script>
  import o_footer  from '../../../components/pages/component/footer.vue'
  import o_head  from '../../../components/pages/component/onexz_head.vue'

  export default {
    props: {
      
    },
    
    mounted() {
      document.title = '资讯-品牌设计全案-广东万喜至工业设计有限公司'; // 设置页面标题
      window.addEventListener("scroll", this.btn_pos);      
      window.scrollTo(0, 0);
    },
    data() {
      return {       
        scrollTop: 0,
        show:0,       
        News_Info:[{"url":"http://www.zq-fa.com/oneXZ_official_website/news/new3.jfif","name":"产教对接·互促共赢 —工业设计高层次人才交流会暨校企合作签约仪式","date":"2023-12-25","see_num":"10","open_url":"https://mp.weixin.qq.com/s/4poMIV9vs7Fbg4ZjgmCgZw"},    
      {"url":"http://www.zq-fa.com/oneXZ_official_website/news/new2.jfif","name":"从概念到落地的创新设计研究","date":"2023-12-20","see_num":"20","open_url":"https://mp.weixin.qq.com/s/BGFcjn3YzlfDwj5QwAwM8w"},
      {"url":"http://www.zq-fa.com/oneXZ_official_website/news/new1.jfif","name":"万喜至再次获得荣誉 |2023东莞杯国际工业设计大赛","date":"2023-12-10","see_num":"96","open_url":"https://mp.weixin.qq.com/s/pxHhE7S9yZybOPh08lNxFQ"},
      
    ],
        
        num:0,
      }
    },
    created(){
      sessionStorage.li_num = 3;
    },
    components: {
      o_footer,o_head
    },
    methods: {
        btn_pos() {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > 400) {
            this.show=1;        
          } else {
            this.show=0;         
          }      
        },
        
      goToContact_work(imgs) {
        // 编程式导航到 / 路径   
        localStorage.setItem("onework_info",JSON.stringify(imgs));
        this.$router.push({name:'onework'});
      },
      open_news(url){
        window.open(url,'_blank');

      },
      
      activeIndex(Index){
          this.num= Index;

      },

    }
  }
</script>
<style>
  .container{
    min-height:100%;
    height: auto !important;
    height: 100%; /*IE6不识别min-height*/
    position: relative;
  }
  
  .page {   
    width:100%;
    margin: 0 auto;  
    margin-top:90px;
    color: #595959;
    font-size: 14px;
  }

  .heard_2 { width: 100%;   padding-top:30px; padding-bottom: 30px; position: fixed; top: 0; z-index: 1000;  padding-left: 5%; background-color: #fff;}
  .logo { float: left; height: 30px; }
  .heard_2  ul li{ float: left; padding: 0 20px 0 20px;list-style: none}
  .heard_2  ul li  {color: #000; font-size:17px; text-shadow: 0px 0px 2px #000000; cursor: pointer;}
  .heard_ul li:hover{ color:#DE303A;  text-shadow: 0px 0px 2px #DE303A;}
  .top_show{opacity: 0; top:-500px; display: none;}
  .top_hide{ opacity: 1; top:0px; display: block;}
  .ch{ display: none}


  .works_title{
    width: 100%;
    max-width: none;
    min-width: 0px;
    height: auto;
    max-height: none;
    min-height: 86px;
    margin-top: 130px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-left: 5%; 
  }


  .latest_case {
    width: 100%;
    padding: 0;
    margin: 30px 0 15px;
    padding:0 5%;
}
.latest_case_more{
   color: #333;
    text-align: left;
    padding: 50px;
    font-weight: 600;
    font-size: 24px;
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.owl-drag{ padding-bottom: 40px;}        
.p_Product{
        background-color: #fff;
        overflow:hidden; 
        position: relative; 
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 0px) / 3) !important;
        border-width: 3px;
        border-style: solid;
        border-color:#fff;
        height: inherit;
        border-radius: 10px;
       transition: all .4s ease-in-out;

}
.latest_case_list{ width:100%; height: auto; float:left;overflow:hidden; }
.latest_case_pic{width: 100%; height: auto; float:left; overflow:hidden; }
.p_Product:hover .latest_case_title{ opacity:1;}
.p_Product:hover .h5_title{ animation: slide-up2 0.5s ease forwards; /* 动画名称，时长，缓动函数，填充模式 */}
.p_Product:hover img{ overflow:hidden; transform: scale(1.1);    transition: all 0.5s;}
   

      
.latest_case_title{position: absolute; top:0; left:0; width:100%; height: 100%;
        background-color: rgba(0,0,0,0.5);
        color:#fff;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        opacity: 0; /*初始时隐藏文本 */
       /* transition: opacity 0.3s ease-in-out; /* 平滑过渡效果 */
         transition: transform 0.5s linear;
      }
  /* 定义动画 */
  @keyframes slide-up2 {
      from {
        transform: translateY(10%); /* 从下边开始 */
        opacity: 0; /* 不透明度为0 */
      }
      to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 完全不透明 */
      }
    }
    

.latest_case_title .h5_title{
  cursor: pointer;
  padding:0 10%  8% 10%;
  text-align: center;
  height: 100%;;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}


.prompt_title{
  width:230px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.seemore_nodata{
  width:100%;
   display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  font-size:18px;
  font-weight: bold;
}
.font_tip{
  padding-left: 10px;
}





/*行业类别css*/

.hc-ul {
    background: #ffffff;
    height: 50px;
    position: relative;
    z-index: 9;
    /* border-style: solid; */
    display: flex;
    justify-content: space-evenly;
    border-radius: 7px;
    margin:0 auto;
  }
.hc-ul li {
    
    float: left;
    width: 230px;
    text-align: center;
    cursor: pointer;
    height: 40px;  
    position: relative;
    z-index: 999;
    list-style-type: none;
    padding: 14px 0 14px 14px;   
    font-size: 15px;
}
.hc-ul li div{
  border-right: 1px solid #dcdbdb; 
  padding: 0 14px 0 0;
}

.hc-ul li:hover{ color:#DE303A; }
.activetext{
  color:#DE303A;
  font-weight: bold;
}























</style>
