<template>
 
  <div class="o_footer">
    <div class="foot_left">
       <img class="logo_f" src="../../../assets/icons/logo_f.png"/>
       <ul class="address_li">
          <li @click="clickLi(1)" :class="num===1? 'li_c':'activetext2' " >广东</li>
          <li @click="clickLi(2)" :class="num===2? 'li_c':'activetext2' ">江苏</li>
       </ul>

       <div class="onexz_name">{{name}}</div>
       <div class="onexz_address">地址：{{address}}</div>   
       <div class="onexz_tel"> Tel：{{tel}}</div>   
       <div class="onexz_fax"> Fax：{{fax}}</div>   
    </div>   

    <div class="foot_right">

      <div class="tel_f">
        <div style="float:left">
          <p>客服电话</p>
          <p style="line-height:70px;">400-805-8511</p>
        </div>
        <div style="float:left; margin-left:20px;">
          <img :src="QR_code[0]" width="80px"/>
          <div style="font-size: 12px; text-align: center;">微信公众号</div>
        </div>
        <div  style="float:left;margin-left: 15px;">
          <img :src="QR_code[1]"  width="80px" style=""/>
          <div style="font-size: 12px; text-align: center;">客服微信</div>
        </div>
      
      </div>

      <div class="else_link">
        <a target="_blank">
          <img alt="" class="wx" src="../../../assets/icons/wx2.png" width="33">        
        </a> 
        <a href="" target="_blank">
          <img alt="" src="../../../assets/icons/fx31.png"  width="33">
        </a> 
        <a href="" target="_blank">
          <img alt="" src="../../../assets/icons/fx1.png"  width="33">
        </a>
        <a href="" target="_blank">
          <img alt="" src="../../../assets/icons/kz.png"  width="33">
        </a>
      </div>     

      <div style="margin-top:30px;color: rgb(124, 124, 124);  font-size: 16px; ">
        友情链接：
        <a href="http://www.zq-fa.com/zq-fa-web/" target="_blank" style="color: rgb(124, 124, 124); text-decoration: none; line-height: 30px;">众企优选</a>
      </div>
      
       <p style="margin-top:10px; font-size: 14px; color:#7c7c7c;">
          © Copyright 2019 广东万喜至工业设计有限公司&nbsp; 
          <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none; font-size: 16px; line-height: 30px;">
            <div style="color:#7c7c7c;">粤ICP备2020073850号</div>
          </a>
      </p> 
    </div>

  </div>

</template>

<script>

  export default {
    props: {
      
    },
    
    mounted() {
      window.addEventListener("scroll", this.btn_pos);
    },
    data() {
      return {            
        num:1,
        name:"广东万喜至工业设计有限公司",
        address:"广东省东莞市松山湖区科技十路4号31栋904-908",
        tel:"0769-23224331        13424706893（万总）  ",
        fax:"0769-23027867",
        QR_code:["http://www.zq-fa.com/oneXZ_official_website/index_footer/kf.jpg","http://www.zq-fa.com/oneXZ_official_website/index_footer/wx.jpg"]
      }
    },
    created(){
     
    },
    components: {
     
    },
    methods: {
      clickLi(index){      
        this.num = index;
        if(index==1){
            this.name="广东万喜至工业设计有限公司";
            this.address="广东省东莞市松山湖区科技十路4号31栋904-908";
            this.tel="0769-23224331  13424706893  （万总）  ";
            this.fax="0769-23027867";     
        }
        else if(index==2){
          this.name="江苏万喜至工业设计有限公司";
            this.address="江苏省海安市镇南路428号高新区科创园C222";
            this.tel="13451689398  （万总）  ";
            this.fax="0769-23027867";   
        }
     },

    }
  }
</script>
<style> 


</style>
