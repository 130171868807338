<template>
  <div style="width:100%; height: 100%;">
    <div class="container">
      <!-- <div class="heard_2">
        <a  href="../index"><img class="logo" src="../../../assets/icons/logo_t.png" /></a>
        <div style="width:510px; height: 30px; float: right;   transition: transform 0.3s linear; " :class="show===0? 'top_hiden':'top_show' ">
          <ul class="heard_ul">
            <li @click="click_e('index')"><span class="en">首页</span><span class="ch">WORK</span></li>
            <li @click="click_e('works')"><span class="en">作品</span><span class="ch">WORK</span></li>
            <li  @click="click_e('about')"><span class="en">关于</span><span class="ch">ABOUT</span></li>
            <li  @click="click_e('news')"><span class="en">新闻</span><span class="ch">NEWS</span></li>
            <li  @click="click_e('')"><span class="en">联系</span><span class="ch">CONTACT</span></li>
            <li ><span class="en">工作</span><span class="ch">JOBS</span></li>      
          </ul>
        </div>
      </div> -->

      <div style=" width: 100%;  height: 90px; position: fixed; top: 0; z-index: 1000;   background-color: #fff; box-shadow:rgba(221, 221, 221,0.5) 0px 0px 20px;">
         <o_head></o_head>
      </div>

      <div class="page">    
      
        <div class="works_title">
          <h2>
            <span style="color:#333">
              <span style="font-family:arial,helvetica,sans-serif">
                <span style="font-size:60px">
                  <strong>CONTACT US</strong>
                </span>
              </span>
            </span>
          </h2>
       </div> 

        <div class="contact_form" >                 
          <div class="div_form">
            <el-input v-model="info.usName" placeholder="请输入姓名"></el-input>
            <el-input v-model="info.companyIndustrySectors" placeholder="公司及行业类型"></el-input>
            <el-input v-model="info.mailbox" placeholder="请输入邮箱"></el-input>
            <el-input v-model="info.phone" placeholder="请输入手机号"></el-input>
            <el-input v-model="info.messageContent" placeholder="请输入留言内容"></el-input>
            <el-button round native-type="submit" @click="addInfo"  class="btn_submit">提交</el-button>
          </div>
        </div>

      </div>

      <div>
        <o_footer></o_footer>
      </div>

    </div>   
  </div>
</template>

<script>
  import o_footer  from '../../../components/pages/component/footer.vue'
  import o_head  from '../../../components/pages/component/onexz_head.vue'

  export default {
    props: {
      
    },
    
    mounted() {
      document.title = '联系-品牌设计全案-广东万喜至工业设计有限公司'; // 设置页面标题
      window.addEventListener("scroll", this.btn_pos);      
      window.scrollTo(0, 0);
    },
    data() {
      return {       
        scrollTop: 0,
        show:0,
        info:{
          usName:'',//` varchar(20) NOT NULL COMMENT '姓名',
          companyIndustrySectors:'',//` varchar(128) NOT NULL COMMENT '公司及行业类型',
          mailbox:'',//` varchar(50) NOT NULL COMMENT '邮箱',
          phone:'',//` varchar(20) NOT NULL COMMENT '手机号',
          messageContent:''//` varchar(512) NOT NULL COMMENT '留言内容',
        },
      
      }
    },
    created(){
      sessionStorage.li_num = 4;
    },
    components: {
      o_footer,o_head
    },
    methods: {
        btn_pos() {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > 400) {
            this.show=1;        
          } else {
            this.show=0;         
          }      
        },
        addInfo(){
          let _this=this;
          this.$http.post_body_onexz("/contact-service/insertContactUs",this.info,function(data){
            _this.$message({
              showClose: true,
              message: '提交成功,工作人员会在24小时内给您回电',
              type: 'success'
            });
            _this.$router.push({name:'index'});
          },function(code,data){
            
          });
        }
    }
  }
</script>
<style>
  .heard_2 { width: 100%;   padding-top:30px; padding-bottom: 30px; position: fixed; top: 0; z-index: 1000;  padding-left: 5%; background-color: #fff;}
  .logo { float: left; height: 30px; }
  .heard_2  ul li{ float: left; padding: 0 20px 0 20px;list-style: none}
  .heard_2  ul li  {color: #000; font-size:17px; text-shadow: 0px 0px 2px #000000; cursor: pointer;}
  .heard_ul li:hover{ color:#DE303A;  text-shadow: 0px 0px 2px #DE303A;}
  .top_show{opacity: 0; top:-500px; display: none;}
  .top_hide{ opacity: 1; top:0px; display: block;}
  .ch{ display: none}


  .works_title{
    width: 100%;
    max-width: none;
    min-width: 0px;
    height: auto;
    max-height: none;
    min-height: 86px;
    margin-top: 130px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-left: 5%; 
  }


  .contact_form {
    width: 100%;
    padding: 0 80px;
    margin: 30px 0 15px;
}

.contact_form .div_form {
    width: 50%;
   
}

.contact_form .div_form input{
  border-radius: 50px;
  height: 50px;
  border:1px solid #000;
  margin-top:10px;
}


.btn_submit{
  background-color: #DE303A !important;
  color:#fff !important;
  font-size: 18px !important; 
  border-radius: 50px !important;
  width:100%;
  height: 50px;
  margin-top:15px !important;
}

</style>
