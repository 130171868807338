import Vue from "vue";
import VueRouter from 'vue-router'
Vue.use(VueRouter);
import index from '../components/pages/index.vue'

import onework  from '../components/pages/component/onework.vue'
import footer  from '../components/pages/component/footer.vue'
import works  from '../components/pages/component/works.vue'
import about  from '../components/pages/component/about.vue'
import contact  from '../components/pages/component/contact.vue'
import news  from '../components/pages/component/news.vue'


// 创建路由器
export default new VueRouter({
    // 可以配置多个路由
    routes : [
        {
            // 用于连接路由器的路径
            path : '/',
            name : '',
            component : index,
          
        },
        {
            // 用于连接路由器的路径
            path : '/index',
            name : 'index',
            component : index,
           
        },
        {
            //尾部
            path : '/footer',
            name : 'footer',
            component : footer
        },
        {
            //单个案例界面
            path : '/onework',
            name : 'onework',
            component : onework
        },
        {
            //所有案例界面
            path : '/works',
            name : 'works',
            component : works
        },
        {
            //关于界面
            path : '/about',
            name : 'about',
            component : about
        },     
        {
            //联系界面
            path : '/contact',
            name : 'contact',
            component : contact
        },     
        {
            //新闻界面
            path : '/news',
            name : 'news',
            component : news
        },     
    ]
});
